.container-bg {
  background-color: #ebecf1;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.login-box {
  background-color: #fff;
  height: 75vh !important;
  width: 60% !important;
  border-radius: 10px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  padding: 0 !important;
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  -ms-border-radius: 10px !important;
  -o-border-radius: 10px !important;
}

.left {
  width: 50%;
  background-color: #908db8;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  height: 100%;
}

.right {
  width: 50%;
}

.align-center {
  height: 100% !important;
  width: 90% !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  margin: auto !important;
}

.small-font {
  font-weight: 500;
}

.btn-next {
  background-color: #908db8;
  color: rgb(255, 0, 0);
  margin: 10px;
}
