.card {
  width: 900px;
  height: 550px;
  border-radius: 25px;
  background: #fff;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
  margin: 20px 0 0;
  padding: 40px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  gap: 40px;
}
