@import url("https://fonts.googleapis.com/css?family=Inter");

.signup {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: inherit;
  width: 88%;
  margin: 0 auto;
}

.signup-img {
  padding: 0 20px 0 20px;
}

.signup-header {
  color: #fff;
  text-align: center;
  font-family: "Manrope";
  font-size: 32px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  text-wrap: balance;
}

.signup-para {
  color: #fff;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-wrap: balance;
  margin-bottom: 30px;
}
