.container-bg {
  background-color: #ebecf1;
  height: 97vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.align-center {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.time-and-test-row {
  display: flex;
  flex-direction: row;
  /* justify-content: space-evenly; */
  gap: 100px;
  align-items: center;
}

.left-content {
  width: 50%;
  height: auto;
  /* border-right: 1px solid #dbdbdb; */
  padding: 10px;
}

.right-content {
  width: 49%;
  height: auto;
  border-left: 2px solid #dbdbdb;
  padding: 10px;
  padding-left: 45px;
  margin-left: 25px !important;
}

.text-gap {
  padding: 10px 0px 10px 0px;
  font-size: 10px;
}

.content-box {
  padding: 10px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  align-items: center;
}

.login-box {
  background-color: #fff;
  height: 60vh;
  width: 65vw;
  padding: 20px 20px;
  border-radius: 10px;
}

.small-font {
  font-weight: 500;
}

.btn-next {
  background-color: #908db8;
  color: rgb(255, 0, 0);
  margin: 10px;
}
