@import url("https://fonts.googleapis.com/css?family=Inter");

.carousel {
  overflow: hidden;
  width: 700px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.inner {
  white-space: nowrap;
  transition: transform 0.3s;
}

.carousel-item {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.carousel-img {
  padding: 0 20px 0 20px;
}

.carousel-item-title {
  color: #fff;
  text-align: center;
  font-family: "Manrope";
  font-size: 32px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  text-wrap: balance;
}

.carousel-item-text {
  color: #fff;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-wrap: balance;
  margin-bottom: 30px;
}

/* final stage */

.carousel-buttons {
  display: flex;
  justify-content: space-evenly;
}

.button-arrow {
  background: none;
  border: none;
  cursor: pointer;
  margin-bottom: 20px;
}

.indicators {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 20px;
}

.indicator-buttons {
  border: none;
  cursor: pointer;
}

.indicators > button {
  margin: 5px;
  background-color: #fff;
  border-radius: 100px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  -ms-border-radius: 100px;
  -o-border-radius: 100px;
  font-size: xx-small;
}

.indicator-symbol {
  color: #fff;
  padding: 1px;
}

.indicator-symbol-active {
  color: #fff;
  padding: 30px 10px;
}
